import asset from "./asset";

export const fields = `
  _type,
  title,
  images[] {
    ${asset("image")},
    position
  }
`;

export const fragment = (name = "gallery") => `${name}{ ${fields} }`;

export default fragment;
