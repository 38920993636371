import asset from "./asset";
import titleListBlock from "./titleListBlock";

export const fields = `
	_type,
  title,
  description,
  ${titleListBlock("listContent")},
  ${asset("equationImage")}
`;

export const fragment = (name = "bursariesDiversity") => `${name}{ ${fields} }`;

export default fragment;
