import React, { useEffect, useRef } from "react";
import { useRouter } from "next/router";

import "normalize.css";
import "../styles/global.scss";

import Layout from "../components/Layout/Layout";
import detect, { isTouchDevice } from "../utils/detect";
import Head from "../components/Head/Head";

import useStore from "../store";

import useRemSizing from "../utils/hooks/use-rem-sizing";
import useVhSizing from "../utils/hooks/use-vh-sizing";
import { pageview } from "../utils/analytics";

const isBrowser = typeof window !== "undefined";

if (isBrowser) {
    require("default-passive-events");
    require("focus-visible");
}

// This default export is required in a new `pages/_app.js` file.
function App({ Component, pageProps }) {
    const router = useRouter();
    const siteSettings = pageProps?.pageData?.siteSettings;
    const layoutRef = useRef();
    const setHeaderData = useStore((state) => state.setHeaderData);
    const setFooterData = useStore((state) => state.setFooterData);
    const setCookieModalData = useStore((state) => state.setCookieModalData);
    const setLoaderData = useStore((state) => state.setLoaderData);

    useRemSizing();
    useVhSizing();

    useEffect(() => {
        const handleRouteChange = (url) => {
            pageview(url);
        };

        //When the component is mounted, subscribe to router changes
        //and log those page views
        router.events.on("routeChangeComplete", handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    useEffect(() => {
        if (!siteSettings) return;
        if (siteSettings.header) setHeaderData(siteSettings.header);
        if (siteSettings.footer) setFooterData(siteSettings.footer);
        if (siteSettings.cookieModal)
            setCookieModalData(siteSettings.cookieModal);
        if (siteSettings.loader) setLoaderData(siteSettings.loader);
    }, [siteSettings]);

    useEffect(() => {
        if (isBrowser) {
            if (
                process.env.NODE_ENV !== "production" &&
                window.location.href.indexOf("?nostat") === -1
            ) {
                require("@jam3/stats")();
            }

            const { device, browser } = detect;
            const classes = [
                isTouchDevice ? "touch-device" : "",
                device.getType(),
                browser.getName(),
            ].filter((className) => Boolean(className));

            document.body.className = [
                ...document.body.className.split(" "),
                ...classes,
            ]
                .filter(Boolean)
                .join(" ");
        }
    }, []);

    return (
        <>
            <Head />

            <Layout pageData={pageProps?.pageData} ref={layoutRef}>
                <Component {...pageProps} />
            </Layout>
        </>
    );
}

export default App;
