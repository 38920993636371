const PASSES = {
    PASS_1: "Pass 1",
    PASS_2: "Pass 2",
    PASS_3: "Pass 3",
    PASS_4: "Pass 4",
};

export default {
    count: 3,
    next: "{{baseUrl}}/api/v3/organization/YOUR-ORGANIZATION-CODE/events/EVENT-CODE/sessions/?page=2&page_size=1",
    previous: null,
    results: [
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 5",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Conference Room A",
            speakers: ["SPEAKER-CODE-2", "SPEAKER-CODE-4"],
            instructions: "Follow your stomach!",
            session_date: "2021-11-22",
            start_time: "12:00:00",
            end_time: "13:30:00",
            tracks: [PASSES.PASS_1],
            audiences: ["Business", "Developers"],
            tags_restrict: ["TAGBWK2QRG2"],
        },
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 1",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Long name for location",
            speakers: ["SPEAKER-CODE-1"],
            instructions: "Follow your stomach!",
            session_date: "2021-11-22",
            start_time: "8:00:00",
            end_time: "9:00:00",
            audiences: ["Business"],
            tracks: [PASSES.PASS_1],
            tags_restrict: ["TAGBWK2QRG2"],
        },
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 2",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Conference Room A",
            speakers: [
                "SPEAKER-CODE-2",
                "SPEAKER-CODE-4",
                "SPEAKER-CODE-5",
                "SPEAKER-CODE-6",
            ],
            instructions: "Follow your stomach!",
            session_date: "2021-11-23",
            start_time: "10:00:00",
            end_time: "11:00:00",
            tracks: [PASSES.PASS_1],
            audiences: ["Business"],
            tags_restrict: ["TAGBWK2QRG2"],
        },
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 3",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Conference Room A",
            speakers: ["SPEAKER-CODE-2"],
            instructions: "Follow your stomach!",
            session_date: "2021-11-24",
            start_time: "10:00:00",
            end_time: "11:00:00",
            tracks: [PASSES.PASS_2],
            audiences: ["Business", "Developers"],
            tags_restrict: ["TAGBWK2QRG2"],
        },
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 4",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Conference Room A",
            speakers: ["SPEAKER-CODE-1"],
            instructions: "Follow your stomach!",
            session_date: "2021-11-25",
            start_time: "10:00:00",
            end_time: "11:00:00",
            tracks: [PASSES.PASS_1],
            audiences: ["Business", "Developers"],
            tags_restrict: ["TAGBWK2QRG2"],
        },
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 6",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Conference Room A",
            speakers: ["SPEAKER-CODE-2"],
            instructions: "Follow your stomach!",
            session_date: "2021-11-23",
            start_time: "13:00:00",
            end_time: "15:30:00",
            tracks: [PASSES.PASS_1],
            audiences: ["Business", "Developers"],
            tags_restrict: ["TAGBWK2QRG2"],
        },
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 7",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Conference Room A",
            speakers: ["SPEAKER-CODE-4"],
            instructions: "Follow your stomach!",
            session_date: "2021-11-24",
            start_time: "12:00:00",
            end_time: "13:30:00",
            tracks: [PASSES.PASS_3],
            audiences: ["Business", "Developers"],
            tags_restrict: ["TAGBWK2QRG2"],
        },
        {
            code: "",
            event: "EVENT-CODE",
            formats: ["Test Format"],
            sub_sessions: ["SES160BYRC13RHLX4"],
            title: "Event 8",
            sub_title: "Just in case you get left behind",
            about: "Come learn about the great Wing of the 1920s!",
            location: "Conference Room A",
            speakers: [
                "SPEAKER-CODE-4",
                "SPEAKER-CODE-3",
                "SPEAKER-CODE-2",
                "SPEAKER-CODE-1",
                "SPEAKER-CODE-5",
            ],
            instructions: "Follow your stomach!",
            session_date: "2021-11-25",
            start_time: "14:00:00",
            end_time: "15:30:00",
            tracks: [PASSES.PASS_4],
            audiences: ["Business", "Developers"],
            tags_restrict: ["TAGBWK2QRG2"],
        },
    ],
};
