import { createClient } from "next-sanity";
import {
    metaData,
    content,
    header,
    footer,
    cookieModal,
    loader,
} from "./fragments";

import speakersStubData from "../components/SpeakersSection/SpeakersSection.stub.js";
import scheduleStubData from "../components/ScheduleSection/ScheduleSection.stub.js";

import { getSpeakers, getSessions } from "./pheedloop";

export const client = createClient({
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
    useCdn: process.env.NEXT_PUBLIC_SANITY_USE_CDN === "true" ? true : false,
});

const settingsFields = `
    _type,
    siteTitle,
    ${metaData.fragment()},
    ${header.fragment()},
    ${footer.fragment()},
    ${cookieModal.fragment()},
    ${loader.fragment()}
`;

const getSiteSettingsPromise = () => {
    return client.fetch(`
    *[_type == "settings"] {
        ${settingsFields}
    }[0]
   `);
};

const getSessionCodes = () => {
    return client.fetch(`
        *[_type == "session"] {
            title,
            sessionCode,
            ticketTypes[]-> {
                title,
                ticketCode
            }
        }
   `);
};

export const getPageStaticPaths = async () => {
    const response = await client.fetch(`*[_type == "page"]`);

    const pathsData = response
        .map((page) => {
            if (page.slug.current === "404") return;

            return {
                params: { slug: page.slug.current },
            };
        })
        .filter((n) => n);

    return pathsData;
};

export const getSiteSettings = async () => {
    const siteSettings = await getSiteSettingsPromise();
    return siteSettings;
};

export const getPagesContentPromise = async () => {
    const fetch = client.fetch(`
        *[_type == 'page'] {
            ${content.fragment()}
        }[0]
    `);

    return fetch;
};

export const getPage = async (slug) => {
    let allContent = await client.fetch(`
        *[_type == "settings" || _type == "page"] {
            _type == "settings" => { 
                ${settingsFields}
            },
            _type == "page" => { 
                _type,
                title,
                slug,
                ${metaData.fragment()},
                ${content.fragment()},
                verticalText
            }
        }
    `);

    const siteSettings = allContent.filter(
        (obj) => obj._type === "settings"
    )[0];

    const allPageData = allContent.filter((obj) => obj._type === "page");

    const pageData = allPageData.filter(
        (page) => page.slug.current === slug
    )[0];

    if (!pageData) return;

    if (pageData.metaData) {
        Object.keys(pageData.metaData).forEach((key) => {
            if (key === "robots") return;

            if (!pageData.metaData[key]) {
                delete pageData.metaData[key];
            }
        });
    }

    /* 
    Schedule + Speakers data
    */
    if (pageData?.content?.length) {
        const hasScheduleData = pageData.content.filter(
            (block) =>
                block._type === "scheduleSection" ||
                block._type === "speakersSection"
        ).length;

        if (hasScheduleData) {
            const [speakersResp, sessionsResp, sessionCodes] =
                await Promise.all([
                    getSpeakers(),
                    getSessions(),
                    getSessionCodes(),
                ]);
            const speakers = speakersResp.data;
            const sessions = sessionsResp.data;

            pageData.content = pageData.content.map((block) => {
                if (
                    block._type !== "scheduleSection" &&
                    block._type !== "speakersSection"
                ) {
                    return block;
                }

                if (block._type === "scheduleSection") {
                    block.speakersData = speakers.results;
                    block.scheduleData = sessions.results;
                    block.sessionCodes = sessionCodes;
                } else if (block._type === "speakersSection") {
                    block.speakersData = speakers.results;
                }

                return block;
            });
        }
    }

    /* 
    Preloading
    */
    const preloadImages = [];
    allPageData.forEach((pageDataObj) => {
        const contentAsString = JSON.stringify(pageDataObj);
        const split = contentAsString.split(`"_type":"extendedImage",`);
        if (!split.length) return;

        split.forEach((str) => {
            const obj = str.substring(0, str.indexOf(',"preload":true'));
            try {
                const parsed = JSON.parse(`{${obj}}`);
                if (Object.keys(parsed).length) preloadImages.push(parsed);
            } catch (e) {}
        });
    });

    const data = {
        ...pageData,
        metaData: {
            ...siteSettings.metaData,
            ...pageData.metaData,
            title: `${siteSettings.siteTitle} ${
                pageData.title ? `| ${pageData.title}` : ""
            }`,
        },
        siteSettings,
        preloadImages,
    };

    return data;
};

export default client;
