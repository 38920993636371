import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import gsap from "gsap";

import styles from "./VerticalText.module.scss";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

import { TRANSITION_TIME } from "../Transition/Transition";

const VerticalText = React.forwardRef(({ className, text, direction }, ref) => {
    const containerRef = useRef();
    const [currentText, setCurrentText] = useState(text);
    const { isMobile } = useBreakpoint();

    let verticalTextSpans = "";
    if (currentText) {
        verticalTextSpans = currentText.split("").map((char, i) => {
            return (
                <span className={styles.VerticalText__char} key={i}>
                    {char}
                </span>
            );
        });
    }

    useEffect(() => {
        const showText = () => {
            gsap.set(containerRef.current, {
                clearProps: "all",
            });
            gsap.fromTo(
                containerRef.current,
                {
                    autoAlpha: 0,
                    y: "10rem",
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    ease: "Power3.easeOut",
                    duration: TRANSITION_TIME * 2,
                    delay: direction === "LEFT" ? 0 : 0.1,
                }
            );
        };

        if (currentText === text && text) {
            showText();
        }

        if (currentText !== text) {
            gsap.to(containerRef.current, {
                autoAlpha: 0,
                duration: TRANSITION_TIME,
                onComplete: () => {
                    setCurrentText(text);
                    showText();
                },
            });
        }
    }, [currentText, text, isMobile]);

    return (
        <div
            ref={(_ref) => {
                ref = _ref;
                containerRef.current = ref;
            }}
            className={classnames(styles.VerticalText, className, {
                [styles[`is-${direction.toLowerCase()}`]]: direction,
            })}
        >
            <div className={styles.inner}>{verticalTextSpans}</div>
        </div>
    );
});

export default memo(VerticalText);
