import React, { memo, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import Head from "../Head/Head";
import Transition, { TRANSITION_TIME_MS } from "../Transition/Transition";
import Header from "../Header/Header";
import IntroLoader from "../IntroLoader/IntroLoader";
import VerticalText from "../VerticalText/VerticalText";

import styles from "./Layout.module.scss";
import useStore from "../../store";

gsap.registerPlugin(ScrollTrigger);

const GridBackground = dynamic(
    () => import("../GridBackground/GridBackground"),
    {
        ssr: false,
    }
);

const CookieModal = dynamic(() => import("../CookieModal/CookieModal"), {
    ssr: false,
});

const Layout = React.forwardRef(({ children, pageData }, ref) => {
    const [showLoader, setShowLoader] = useState(true);
    const [showHeader, setShowHeader] = useState(false);
    const setNavIsOpen = useStore((state) => state.setNavIsOpen);
    const router = useRouter();
    const setHomeIsFinishedAnimating = useStore(
        (state) => state.setHomeIsFinishedAnimating
    );
    const { asPath } = router;
    const scrollContainerRef = useRef();
    const scrollPositionY = useStore((state) => state.scrollPositionY);
    const setContentHeight = useStore((state) => state.setContentHeight);
    const prevContentHeight = useRef(0);

    // Using hashes to link
    useEffect(() => {
        if (showLoader || !window.location.hash) return;

        setTimeout(() => {
            const element = document.querySelectorAll(window.location.hash)[0];
            if (!element) return;
            const offset = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo(0, offset);
        }, 1000);
    }, [showLoader, router]);

    useEffect(() => {
        const body = document.body,
            html = document.documentElement;

        const height = Math.max(
            body.scrollHeight,
            body.offsetHeight,
            html.clientHeight,
            html.scrollHeight,
            html.offsetHeight
        );

        if (height !== prevContentHeight.current) {
            prevContentHeight.current = height;
            setContentHeight(height);
        }
    }, [scrollPositionY]);

    useEffect(() => {
        document.body.classList[showLoader ? "add" : "remove"]("loading");
    }, [showLoader]);

    useEffect(() => {
        setNavIsOpen(false);

        if (asPath !== "/") {
            setTimeout(() => {
                setHomeIsFinishedAnimating(false);
            }, TRANSITION_TIME_MS);
        }
    }, [asPath]);

    return (
        <div className={styles.Layout} ref={ref}>
            <Head
                title={pageData?.metaData?.title}
                description={pageData?.metaData?.description}
                keywords={pageData?.metaData?.keywords}
                shareImageUrl={pageData?.metaData?.shareAsset?.asset?.url}
                robots={pageData?.metaData?.robots}
            />
            {pageData && !showLoader && (
                <>
                    <VerticalText
                        text={pageData?.verticalText}
                        direction="LEFT"
                    />
                    <VerticalText
                        text={pageData?.verticalText}
                        direction="RIGHT"
                    />
                </>
            )}
            <GridBackground active={!showLoader} />
            <Header active={showHeader} />
            {showLoader && (
                <IntroLoader
                    preloadImages={pageData?.preloadImages}
                    onComplete={() => {
                        setShowHeader(true);
                        setShowLoader(false);
                    }}
                />
            )}
            {/* {!showLoader && <CookieModal />} */}
            <div className={styles.scrollContainer}>
                <div
                    className={styles.scrollContainerInner}
                    ref={scrollContainerRef}
                >
                    {!showLoader && (
                        <Transition
                            isActive={true}
                            location={asPath}
                            scrollContainerRef={scrollContainerRef}
                        >
                            {children}
                        </Transition>
                    )}
                </div>
            </div>
        </div>
    );
});

export default memo(Layout);
