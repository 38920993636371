export default {
    count: 24,
    next: "https://api.pheedloop.com/api/v3/organization/YOUR-ORGANIZATION-CODE/events/EVENT-CODE/speakers/?page=3&page_size=1",
    previous:
        "https://api.pheedloop.com/api/v3/organization/YOUR-ORGANIZATION-CODE/events/EVENT-CODE/speakers/?page_size=1",
    results: [
        {
            code: "SPEAKER-CODE-1",
            event: "EVENT-CODE",
            first_name: "Tim",
            last_name: "Cook",
            email: "timcook@apple.com",
            title: "CEO",
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dapibus pharetra purus, in condimentum ipsum sagittis eget. Donec ac ligula vel purus aliquam pharetra. Mauris finibus eros sit amet mi ullamcorper cursus. Aliquam congue arcu turpis, quis gravida tortor tristique iaculis. Nam pulvinar ultrices lorem eu dictum. Quisque tincidunt, massa a gravida maximus, magna neque finibus nisl, sed fermentum orci tellus quis lectus. Ut malesuada aliquam tortor. Aliquam sem magna, ultricies sed risus quis, mattis dictum diam. Duis et commodo sapien, at molestie purus. Sed laoreet tincidunt odio, varius dapibus ipsum lacinia sit amet.",
            organization: "Apple",
            location: "Cupertino",
            sessions: ["SESSION-CODE"],
            tags: ["Speaker Tag"],
            picture: "https://unsplash.it/300?random",
            logo_company:
                "https://api.pheedloop.com/media/events/EVENT-CODE/speakers/image_SPEUBRZCCTT_311604.png",
        },
        {
            code: "SPEAKER-CODE-2",
            event: "EVENT-CODE",
            first_name: "Tim2",
            last_name: "Cook2",
            email: "timcook@apple.com",
            title: "CEO",
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dapibus pharetra purus, in condimentum ipsum sagittis eget. Donec ac ligula vel purus aliquam pharetra. Mauris finibus eros sit amet mi ullamcorper cursus. Aliquam congue arcu turpis, quis gravida tortor tristique iaculis. Nam pulvinar ultrices lorem eu dictum. Quisque tincidunt, massa a gravida maximus, magna neque finibus nisl, sed fermentum orci tellus quis lectus. Ut malesuada aliquam tortor. Aliquam sem magna, ultricies sed risus quis, mattis dictum diam. Duis et commodo sapien, at molestie purus. Sed laoreet tincidunt odio, varius dapibus ipsum lacinia sit amet.",
            organization: "Apple",
            location: "Cupertino",
            sessions: ["SESSION-CODE"],
            tags: ["Speaker Tag"],
            picture: "https://unsplash.it/301?random",
            logo_company:
                "https://api.pheedloop.com/media/events/EVENT-CODE/speakers/image_SPEUBRZCCTT_311604.png",
        },
        {
            code: "SPEAKER-CODE-3",
            event: "EVENT-CODE",
            first_name: "Tim3",
            last_name: "Cook3",
            email: "timcook@apple.com",
            title: "CEO",
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dapibus pharetra purus, in condimentum ipsum sagittis eget. Donec ac ligula vel purus aliquam pharetra. Mauris finibus eros sit amet mi ullamcorper cursus. Aliquam congue arcu turpis, quis gravida tortor tristique iaculis. Nam pulvinar ultrices lorem eu dictum. Quisque tincidunt, massa a gravida maximus, magna neque finibus nisl, sed fermentum orci tellus quis lectus. Ut malesuada aliquam tortor. Aliquam sem magna, ultricies sed risus quis, mattis dictum diam. Duis et commodo sapien, at molestie purus. Sed laoreet tincidunt odio, varius dapibus ipsum lacinia sit amet.",
            organization: "Apple",
            location: "Cupertino",
            sessions: ["SESSION-CODE"],
            tags: ["Speaker Tag"],
            picture: "https://unsplash.it/302?random",
            logo_company:
                "https://api.pheedloop.com/media/events/EVENT-CODE/speakers/image_SPEUBRZCCTT_311604.png",
        },
        {
            code: "SPEAKER-CODE-4",
            event: "EVENT-CODE",
            first_name: "Tim4",
            last_name: "Cook4",
            email: "timcook@apple.com",
            title: "CEO",
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dapibus pharetra purus, in condimentum ipsum sagittis eget. Donec ac ligula vel purus aliquam pharetra. Mauris finibus eros sit amet mi ullamcorper cursus. Aliquam congue arcu turpis, quis gravida tortor tristique iaculis. Nam pulvinar ultrices lorem eu dictum. Quisque tincidunt, massa a gravida maximus, magna neque finibus nisl, sed fermentum orci tellus quis lectus. Ut malesuada aliquam tortor. Aliquam sem magna, ultricies sed risus quis, mattis dictum diam. Duis et commodo sapien, at molestie purus. Sed laoreet tincidunt odio, varius dapibus ipsum lacinia sit amet.",
            organization: "Apple",
            location: "Cupertino",
            sessions: ["SESSION-CODE"],
            tags: ["Speaker Tag"],
            picture: "https://unsplash.it/303?random",
            logo_company:
                "https://api.pheedloop.com/media/events/EVENT-CODE/speakers/image_SPEUBRZCCTT_311604.png",
        },
        {
            code: "SPEAKER-CODE-5",
            event: "EVENT-CODE",
            first_name: "Tim5",
            last_name: "Cook5",
            email: "timcook@apple.com",
            title: "CEO",
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dapibus pharetra purus, in condimentum ipsum sagittis eget. Donec ac ligula vel purus aliquam pharetra. Mauris finibus eros sit amet mi ullamcorper cursus. Aliquam congue arcu turpis, quis gravida tortor tristique iaculis. Nam pulvinar ultrices lorem eu dictum. Quisque tincidunt, massa a gravida maximus, magna neque finibus nisl, sed fermentum orci tellus quis lectus. Ut malesuada aliquam tortor. Aliquam sem magna, ultricies sed risus quis, mattis dictum diam. Duis et commodo sapien, at molestie purus. Sed laoreet tincidunt odio, varius dapibus ipsum lacinia sit amet.",
            organization: "Apple",
            location: "Cupertino",
            sessions: ["SESSION-CODE"],
            tags: ["Speaker Tag"],
            picture: null,
            logo_company:
                "https://api.pheedloop.com/media/events/EVENT-CODE/speakers/image_SPEUBRZCCTT_311604.png",
        },
        {
            code: "SPEAKER-CODE-6",
            event: "EVENT-CODE",
            first_name: "Tim6",
            last_name: "Cook6",
            email: "timcook@apple.com",
            title: "CEO",
            about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dapibus pharetra purus, in condimentum ipsum sagittis eget. Donec ac ligula vel purus aliquam pharetra. Mauris finibus eros sit amet mi ullamcorper cursus. Aliquam congue arcu turpis, quis gravida tortor tristique iaculis. Nam pulvinar ultrices lorem eu dictum. Quisque tincidunt, massa a gravida maximus, magna neque finibus nisl, sed fermentum orci tellus quis lectus. Ut malesuada aliquam tortor. Aliquam sem magna, ultricies sed risus quis, mattis dictum diam. Duis et commodo sapien, at molestie purus. Sed laoreet tincidunt odio, varius dapibus ipsum lacinia sit amet.",
            organization: "Apple",
            location: "Cupertino",
            sessions: ["SESSION-CODE"],
            tags: ["Speaker Tag"],
            picture: "https://unsplash.it/305?random",
            logo_company:
                "https://api.pheedloop.com/media/events/EVENT-CODE/speakers/image_SPEUBRZCCTT_311604.png",
        },
    ],
};
