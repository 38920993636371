import titleTextBlock from "./titleTextBlock";
import link from "./link";

export const fields = `
	_type,
  ${titleTextBlock("intro")},
  ${titleTextBlock("howToGetThere")},
  ${titleTextBlock("whereToStay")},
  rectangeText,
  ${link("rectangleTextLink")}
`;

export const fragment = (name = "aboutEdmontonSection") =>
    `${name}{ ${fields} }`;

export default fragment;
