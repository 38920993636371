import asset from "./asset";
import richText from "./richText";

export const fields = `
	_type,
  title,
  month,
  dates,
  year,
  ${asset("imageDesktop")},
  ${asset("imageMobile")},
  bubbleText,
  ${richText()}
`;

export const fragment = (name = "homeHero") => `${name}{ ${fields} }`;

export default fragment;
