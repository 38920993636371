import asset from "./asset";

export const fields = `
	_type,
  ${asset("backImage")}
`;

export const fragment = (name = "loader") => `${name}{ ${fields} }`;

export default fragment;
