import richText from "./richText";

export const fields = `
  _type,
  theme,
  hasBorder,
  hasStickyHeader,
  titleSize,
  title,
  icon,
  ${richText("content")},
  contentSize
`;

export const fragment = (name = "titleTextBlock") => `${name}{ ${fields} }`;

export default fragment;
