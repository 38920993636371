import React, { memo } from "react";
import NextLink from "next/link";
import classnames from "classnames";

import { event } from "../../utils/analytics";

const Link = React.forwardRef(
    (
        { className, children, link, onMouseEnter, onMouseLeave, linkOnly },
        ref
    ) => {
        const { linkType, label, link: url } = link;

        if (linkType === "Internal" && !url?.slug) return null;
        if (linkType === "External" && !url) return null;

        if (linkType === "External") {
            return (
                <a
                    ref={ref}
                    href={url}
                    className={classnames(className)}
                    target="_blank"
                    onMouseEnter={() => {
                        if (onMouseEnter) onMouseEnter();
                    }}
                    onMouseLeave={() => {
                        if (onMouseLeave) onMouseLeave();
                    }}
                >
                    {label && !children && !linkOnly && label}
                    {children && children}
                </a>
            );
        } else if (linkType === "Internal") {
            let slug = url.slug;

            if (slug === "home") {
                slug = "";
            }

            return (
                <NextLink
                    ref={ref}
                    className={classnames(className)}
                    href={`/${slug}`}
                    scroll={false}
                    onMouseEnter={() => {
                        if (onMouseEnter) onMouseEnter();
                    }}
                    onMouseLeave={() => {
                        if (onMouseLeave) onMouseLeave();
                    }}
                >
                    {label && !children && label}
                    {children && children}
                </NextLink>
            );
        }
    }
);

export default memo(Link);
