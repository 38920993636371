import link, { fields as linkFields } from "./link";

export const fields = `
	_type,
  ctas[] {
    ${link("cta")},
    title
  }
`;

export const fragment = (name = "ctaSection") => `${name}{ ${fields} }`;

export default fragment;
