import asset from "./asset";

export const fields = `
	_type,
  title,
  images {
    ${asset("topImage")},
    ${asset("bottomImage")}
  }
`;

export const fragment = (name = "speakersSection") => `${name}{ ${fields} }`;

export default fragment;
