import React, { memo, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import Lottie from "lottie-react";

import styles from "./Header.module.scss";

import useStore from "../../store";
import Link from "../Link/Link";
import LogoFilled from "../../assets/svgs/intro-loader-logo-filled.svg";

import InstagramLogo from "../../assets/svgs/instagram.svg";
import TwitterLogo from "../../assets/svgs/twitter.svg";
import LinkedInLogo from "../../assets/svgs/linkedin.svg";

import GooglePlay from "../../assets/images/googlePlay.webp";
import AppStore from "../../assets/images/appStore.webp";

import CrossLottie from "../../assets/lottie/x.json";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

export const headerLogoClassName = styles.logoContainerInner;

function Header({ className, active }) {
    const headerData = useStore((state) => state.headerData);
    const setNavIsOpen = useStore((state) => state.setNavIsOpen);
    const navIsOpen = useStore((state) => state.navIsOpen);
    const fixedVideoOpen = useStore((state) => state.fixedVideoOpen);
    const cameFromLoader = useStore((state) => state.cameFromLoader);
    const intervalRef = useRef(null);
    const [date, setDate] = useState({ seconds: 0, minutes: 0, hours: 0 });
    const [menuIndexHovering, setMenuIndexHovering] = useState(null);
    const [eventIsLive, setEventIsLive] = useState(false);
    const { isMobile } = useBreakpoint();

    //Lottie
    const lottieRef = useRef([]);

    //Header
    const headerRef = useRef();
    const innerLines = useRef([]);
    const logoContainerInnerBgRef = useRef();
    const descriptionLineRefs = useRef([]);
    const socialSvgContainerRefs = useRef([]);
    const socialSvgLineRefs = useRef([]);
    const headerButtons = useRef([]);

    // Nav
    const navRef = useRef();
    const navBgRef = useRef();
    const footerBgRef = useRef();
    const navMiddleLineRef = useRef();
    const linkLinesRef = useRef([]);
    const navText = useRef({
        topLinks: [],
        colHeadings: [],
        countdownComponents: [],
        sideText: null,
        footerLinks: [],
    });
    const countdownTextRef = useRef();
    const downloadAppBgRef = useRef();
    const downloadAppTextRef = useRef();
    const downloadButtonsRef = useRef();

    useEffect(() => {
        if (!headerData.countdownEndDate || intervalRef.current) return;

        const parsed = headerData.countdownEndDate.split("-");
        const endMonth = parseInt(parsed[0]) - 1;
        const endDay = parseInt(parsed[1]);
        const endYear = parseInt(parsed[2]);

        const endDate = new Date(endYear, endMonth, endDay, 0, 0, 0, 0);
        const endDateInMs = endDate.getTime();

        const getTime = () => {
            const now = new Date().getTime();
            const secondsUntil = Math.floor(
                parseInt((endDateInMs - now) / 1000)
            );
            const minutesUntil = Math.floor(secondsUntil / 60);
            const hoursUntil = Math.floor(minutesUntil / 60);
            const daysUntil = Math.floor(hoursUntil / 24);

            const countdownSecondsUntil = Math.floor(secondsUntil % 60);
            const countdownMinutesUntil = Math.floor(minutesUntil % 60);

            if (countdownMinutesUntil <= -0) return setEventIsLive(true);

            // Format to have the 00 in front

            const hours =
                hoursUntil > 999
                    ? ">1k"
                    : `0${
                          `${hoursUntil}`.length === 1
                              ? `0${hoursUntil}`
                              : hoursUntil
                      }`;

            setDate({
                seconds: `0${
                    `${countdownSecondsUntil}`.length === 1
                        ? `0${countdownSecondsUntil}`
                        : countdownSecondsUntil
                }`,
                minutes: `0${
                    `${countdownMinutesUntil}`.length === 1
                        ? `0${countdownMinutesUntil}`
                        : countdownMinutesUntil
                }`,
                hours,
                days: daysUntil,
            });
        };

        getTime();

        intervalRef.current = setInterval(() => {
            getTime();
        }, 1000);
    }, [headerData.countdownEndDate]);

    const handleMenuClick = () => {
        setNavIsOpen(!navIsOpen);
    };

    const animateInNav = () => {
        if (!navText.current.topLinks.length) return;

        const duration = 1.2;

        gsap.killTweensOf([
            navBgRef.current,
            ...navText.current.topLinks,
            ...navText.current.colHeadings,
            ...navText.current.countdownComponents,
            navText.current.sideText,
            ...navText.current.footerLinks,
            footerBgRef.current,
            ...linkLinesRef.current,
            navMiddleLineRef.current,
            countdownTextRef.current,
            downloadAppBgRef.current,
            downloadAppTextRef.current,
            downloadButtonsRef.current,
        ]);

        gsap.set(navRef.current, {
            pointerEvents: "all",
        });

        gsap.fromTo(
            linkLinesRef.current,
            {
                x: "-100%",
            },
            {
                stagger: 0.08,
                x: 0,
                duration,
                ease: "Power3.easeOut",
            }
        );

        gsap.to([navBgRef.current, navMiddleLineRef.current], {
            scaleY: 1,
            pointerEvents: "all",
            duration,
            ease: "Power3.easeOut",
            stagger: 0.1,
        });

        gsap.to(navText.current.colHeadings, {
            autoAlpha: 1,
            duration,
            stagger: 0.08,
        });

        if (navText.current.countdownComponents[0]) {
            gsap.to(navText.current.countdownComponents, {
                autoAlpha: 1,
                duration,
                stagger: 0.08,
            });
        }

        if (countdownTextRef.current) {
            gsap.to(countdownTextRef.current, {
                autoAlpha: 1,
                duration,
            });
        }

        gsap.to([...navText.current.topLinks, navText.current.sideText], {
            autoAlpha: 1,
            duration,
            stagger: 0.08,
        });

        gsap.to([...navText.current.footerLinks], {
            autoAlpha: 1,
            duration,
            delay: duration * 0.7,
        });

        gsap.fromTo(
            footerBgRef.current,
            {
                x: "-101%",
            },
            {
                x: 0,
                ease: "Power4.easeOut",
                duration,
                delay: duration * 0.7,
            }
        );

        if (
            downloadAppBgRef.current &&
            downloadAppTextRef.current &&
            downloadButtonsRef.current
        ) {
            gsap.to(downloadAppBgRef.current, {
                scaleY: 1,
                ease: "Power4.easeOut",
                duration,
            });

            gsap.to([downloadAppTextRef.current, downloadButtonsRef.current], {
                autoAlpha: 1,
                duration,
                delay: duration * 0.5,
            });
        }
    };

    const animateOutNav = () => {
        if (!navText.current.topLinks.length) return;

        const duration = 0.4;

        gsap.killTweensOf([
            navBgRef.current,
            ...navText.current.topLinks,
            ...navText.current.colHeadings,
            ...navText.current.countdownComponents,
            navText.current.sideText,
            ...navText.current.footerLinks,
            footerBgRef.current,
            ...linkLinesRef.current,
            navMiddleLineRef.current,
            countdownTextRef.current,
            downloadAppBgRef.current,
            downloadAppTextRef.current,
            downloadButtonsRef.current,
        ]);

        if (countdownTextRef.current) {
            gsap.to(countdownTextRef.current, {
                autoAlpha: 0,
                duration,
            });
        }

        gsap.set(navRef.current, {
            pointerEvents: "none",
        });

        gsap.to(linkLinesRef.current, {
            stagger: duration * 0.1,
            x: "101%",
            duration,
            ease: "Power3.easeOut",
        });

        gsap.to([navBgRef.current, navMiddleLineRef.current], {
            scaleY: 0,
            pointerEvents: "none",
            duration,
            ease: "Power3.easeOut",
            stagger: 0.1,
        });

        let toFadeOut = [
            ...navText.current.topLinks,
            ...navText.current.colHeadings,
            navText.current.sideText,
            ...navText.current.footerLinks,
        ];

        if (
            downloadAppBgRef.current &&
            downloadAppTextRef.current &&
            downloadButtonsRef.current
        ) {
            gsap.to(downloadAppBgRef.current, {
                scaleY: 0,
                ease: "Power4.easeOut",
                duration,
            });

            toFadeOut = [
                ...toFadeOut,
                downloadAppTextRef.current,
                downloadButtonsRef.current,
            ];
        }

        if (navText.current.countdownComponents[0]) {
            toFadeOut = [...toFadeOut, ...navText.current.countdownComponents];
        }

        gsap.to(toFadeOut, {
            autoAlpha: 0,
            duration,
        });

        gsap.to(footerBgRef.current, {
            x: "101%",
            duration,
        });
    };

    const animateInHeader = () => {
        if (!innerLines.current[0]) return;

        const duration = 0.7;
        const ease = "Power4.easeInOut";

        gsap.to(innerLines.current, {
            duration: duration * 2,
            ease,
            scaleX: 1,
            stagger: 0.15,
        });

        gsap.to(logoContainerInnerBgRef.current, {
            duration: cameFromLoader ? 0 : duration,
            delay: cameFromLoader ? 0 : duration * 0.5,
            ease,
            scaleY: 1,
        });

        gsap.to(descriptionLineRefs.current, {
            duration: duration,
            stagger: {
                from: "end",
                each: 0.1,
            },
            autoAlpha: 1,
            delay: duration,
        });

        gsap.to(socialSvgContainerRefs.current, {
            duration: duration,
            stagger: 0.1,
            autoAlpha: 1,
            delay: duration,
        });

        gsap.to(socialSvgLineRefs.current, {
            duration: duration,
            stagger: 0.1,
            scaleY: 1,
        });

        gsap.to(headerButtons.current, {
            duration: duration,
            stagger: 0.1,
            delay: duration,
            ease: "Power3.easeOut",
            y: 1,
        });
    };

    useEffect(() => {
        if (!headerRef.current) return;

        gsap.to(headerRef.current, {
            pointerEvents: fixedVideoOpen ? "none" : "all",
            autoAlpha: fixedVideoOpen ? 0 : 1,
            duration: 0.2,
        });
    }, [fixedVideoOpen]);

    useEffect(() => {
        navIsOpen ? animateInNav() : animateOutNav();
    }, [navIsOpen]);

    useEffect(() => {
        animateInHeader();
    }, [active]);

    if (!headerData || !headerData?.navLinks?.length) return null;

    return (
        <>
            <header
                className={classnames(
                    styles.Header,
                    className,
                    {
                        [styles.isActive]: active,
                    },
                    { [styles.navIsOpen]: navIsOpen }
                )}
                ref={headerRef}
            >
                <div className={styles.inner}>
                    <div
                        ref={(ref) => (innerLines.current[0] = ref)}
                        className={classnames(
                            styles.innerLine,
                            styles.innerLine1
                        )}
                    />
                    <div
                        ref={(ref) => (innerLines.current[1] = ref)}
                        className={classnames(
                            styles.innerLine,
                            styles.innerLine2
                        )}
                    />
                    <div className={styles.leftSide}>
                        <Link link={headerData.logoLink}>
                            <div className={styles.logoContainer}>
                                <div className={styles.logoContainerInner}>
                                    <div
                                        className={styles.logoContainerInnerBg}
                                        ref={logoContainerInnerBgRef}
                                    />
                                    <LogoFilled className={styles.logo} />
                                </div>
                            </div>
                        </Link>
                        <div className={styles.descriptionLines}>
                            {headerData.descriptionLine1 && (
                                <div
                                    ref={(ref) =>
                                        (descriptionLineRefs.current[0] = ref)
                                    }
                                    className={styles.descriptionLine1}
                                >
                                    {headerData.descriptionLine1}
                                </div>
                            )}
                            {headerData.descriptionLine2 && (
                                <div
                                    ref={(ref) =>
                                        (descriptionLineRefs.current[1] = ref)
                                    }
                                    className={styles.descriptionLine2}
                                >
                                    {headerData.descriptionLine2}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.rightSide}>
                        <div className={styles.social}>
                            {headerData.linkedInLink && (
                                <a
                                    className={classnames(
                                        styles.socialLink,
                                        styles.instagram
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={headerData.linkedInLink.link}
                                    aria-label="Follow us on LinkedIn"
                                >
                                    <span
                                        ref={(ref) =>
                                            (socialSvgLineRefs.current[0] = ref)
                                        }
                                        className={styles.socialLine}
                                    />
                                    <span
                                        ref={(ref) =>
                                            (socialSvgContainerRefs.current[0] =
                                                ref)
                                        }
                                        className={styles.socialSvgContainer}
                                    >
                                        <LinkedInLogo />
                                    </span>
                                </a>
                            )}
                            {headerData.instagramLink && (
                                <a
                                    className={classnames(
                                        styles.socialLink,
                                        styles.instagram
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={headerData.instagramLink.link}
                                    aria-label="Follow us on Instagram"
                                >
                                    <span
                                        ref={(ref) =>
                                            (socialSvgLineRefs.current[1] = ref)
                                        }
                                        className={styles.socialLine}
                                    />
                                    <span
                                        ref={(ref) =>
                                            (socialSvgContainerRefs.current[1] =
                                                ref)
                                        }
                                        className={styles.socialSvgContainer}
                                    >
                                        <InstagramLogo />
                                    </span>
                                </a>
                            )}
                            {headerData.twitterLink && (
                                <a
                                    className={classnames(
                                        styles.socialLink,
                                        styles.twitter
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Follow us on Twitter"
                                    href={headerData.twitterLink.link}
                                >
                                    <span
                                        ref={(ref) =>
                                            (socialSvgLineRefs.current[2] = ref)
                                        }
                                        className={styles.socialLine}
                                    />
                                    <span
                                        ref={(ref) =>
                                            (socialSvgLineRefs.current[3] = ref)
                                        }
                                        className={classnames(
                                            styles.socialLine,
                                            styles.socialLineRight
                                        )}
                                    />
                                    <span
                                        ref={(ref) =>
                                            (socialSvgContainerRefs.current[2] =
                                                ref)
                                        }
                                        className={styles.socialSvgContainer}
                                    >
                                        <TwitterLogo />
                                    </span>
                                </a>
                            )}
                        </div>
                        <div className={styles.navTopLevelLinks}>
                            <button
                                onClick={handleMenuClick}
                                className={styles.menuButton}
                                ref={(ref) => (headerButtons.current[0] = ref)}
                            >
                                Menu
                            </button>
                            {headerData.ticketLink && (
                                <Link
                                    className={styles.topLevelLink}
                                    link={headerData.ticketLink}
                                    ref={(ref) =>
                                        (headerButtons.current[1] = ref)
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <nav
                ref={navRef}
                className={classnames(styles.nav, {
                    [styles.navIsOpen]: navIsOpen,
                })}
            >
                <div className={styles.navBg} ref={navBgRef} />
                <div className={styles.navMiddleLine} ref={navMiddleLineRef} />
                <div className={styles.navInner}>
                    <div
                        className={classnames(
                            styles.navGridContainer,
                            styles.navHeader
                        )}
                    >
                        <div
                            className={styles.navGridLine}
                            ref={(ref) => (linkLinesRef.current[0] = ref)}
                        />
                        <div
                            ref={(ref) =>
                                (navText.current.colHeadings[0] = ref)
                            }
                            className={styles.navGridContainerItem}
                        >
                            Select
                        </div>
                        <div
                            ref={(ref) =>
                                (navText.current.colHeadings[1] = ref)
                            }
                            className={styles.navGridContainerItem}
                        >
                            Category
                        </div>
                    </div>
                    <div className={styles.navLinks}>
                        {headerData.navLinks.map((navLink, i) => (
                            <Link
                                className={classnames(
                                    styles.navGridContainer,
                                    styles.navGridContainerHeader
                                )}
                                link={navLink}
                                key={i}
                                onMouseEnter={() => {
                                    if (!isMobile) setMenuIndexHovering(i);
                                }}
                                onMouseLeave={() => {
                                    if (!isMobile) setMenuIndexHovering(null);
                                }}
                            >
                                <div
                                    ref={(ref) =>
                                        (linkLinesRef.current[i + 1] = ref)
                                    }
                                    className={styles.navGridLine}
                                />
                                <div
                                    className={classnames(
                                        styles.navGridContainerItem
                                    )}
                                >
                                    {i === menuIndexHovering && (
                                        <div className={styles.lottieContainer}>
                                            <Lottie
                                                lottieRef={lottieRef}
                                                animationData={CrossLottie}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    ref={(ref) =>
                                        (navText.current.topLinks[i] = ref)
                                    }
                                    className={styles.navGridContainerItem}
                                >
                                    <span className={styles.navLinkLabel}>
                                        {navLink.label}
                                    </span>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div className={styles.countdownContent}>
                        {eventIsLive ? (
                            <div className={styles.downloadAppContainer}>
                                <div
                                    className={styles.downloadAppContainerBg}
                                    ref={downloadAppBgRef}
                                />
                                <div
                                    className={styles.downloadAppContainerInner}
                                >
                                    <p
                                        className={styles.downloadAppText}
                                        ref={downloadAppTextRef}
                                    >
                                        {headerData.appDownloadText}
                                    </p>
                                    <div
                                        className={styles.downloadAppButtons}
                                        ref={downloadButtonsRef}
                                    >
                                        <Link
                                            className={
                                                styles.downloadButtonWrapper
                                            }
                                            link={headerData.appStoreLink}
                                        >
                                            <img
                                                src={AppStore.src}
                                                alt="App Store"
                                                className={
                                                    styles.downloadAppButtonImage
                                                }
                                                width={155}
                                                height={46}
                                            />
                                        </Link>
                                        <Link
                                            className={
                                                styles.downloadButtonWrapper
                                            }
                                            link={headerData.googlePlayLink}
                                        >
                                            <img
                                                src={GooglePlay.src}
                                                alt="App Store"
                                                className={
                                                    styles.downloadAppButtonImage
                                                }
                                                width={155}
                                                height={46}
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <p
                                    className={styles.countdownText}
                                    ref={countdownTextRef}
                                >
                                    {headerData.countdownText}
                                </p>
                                <span className={styles.countdown}>
                                    <span
                                        ref={(ref) =>
                                            (navText.current.countdownComponents[0] =
                                                ref)
                                        }
                                        className={styles.countdownHour}
                                    >
                                        {date.hours}
                                    </span>
                                    <span
                                        ref={(ref) =>
                                            (navText.current.countdownComponents[1] =
                                                ref)
                                        }
                                        className={styles.countdownMinutes}
                                    >
                                        {date.minutes}
                                    </span>
                                    <span
                                        ref={(ref) =>
                                            (navText.current.countdownComponents[2] =
                                                ref)
                                        }
                                        className={styles.countdownSeconds}
                                    >
                                        {date.seconds}
                                    </span>
                                </span>
                            </>
                        )}
                    </div>
                    {headerData.footerLinks && (
                        <footer className={styles.footer}>
                            <div
                                className={styles.footerBg}
                                ref={footerBgRef}
                            />
                            {headerData.footerLinks.map((link, i) => (
                                <Link
                                    ref={(ref) =>
                                        (navText.current.footerLinks[i] = ref)
                                    }
                                    key={i}
                                    link={link}
                                    className={styles.footerLink}
                                />
                            ))}
                        </footer>
                    )}
                    {headerData.navigationLeftSideText && (
                        <div
                            className={styles.navSideText}
                            ref={(ref) => (navText.current.sideText = ref)}
                        >
                            {headerData.navigationLeftSideText}
                        </div>
                    )}
                </div>
            </nav>
        </>
    );
}

export default memo(Header);
