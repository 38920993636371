import asset from "./asset";
import titleTextBlock from "./titleTextBlock";
import titleListBlock from "./titleListBlock";

export const fields = `
	_type,
  ${titleTextBlock("hero")},
  ${titleListBlock("benefits")},
  images {
    ${asset("imageTop")},
    ${asset("equation")},
  }
`;

export const fragment = (name = "bursariesHero") => `${name}{ ${fields} }`;

export default fragment;
