import asset from "./asset";
import richText from "./richText";

export const fields = `
	_type,
  boxTitle,
  ${richText("boxContent")},
  ${asset("image")}
`;

export const fragment = (name = "aboutBoxSection") => `${name}{ ${fields} }`;

export default fragment;
