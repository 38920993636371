import richText from "./richText";

export const fields = `
  _type,
  items[] {
    question,
    ${richText("answer")}
  }
`;

export const fragment = (name = "faqAccordion") => `${name}{ ${fields} }`;

export default fragment;
