import asset from "./asset";
import link from "./link";

export const fields = `
	_type,
  hasTopSpacingBox,
  ${asset("graphicBelow")},
  ticketTypes[]-> {
    title,
    price,
    itemsIncluded,
    ${link()},
    isHighlighted
  }
`;

export const fragment = (name = "ticketPlans") => `${name}{ ${fields} }`;

export default fragment;
