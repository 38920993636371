import asset from "./asset";
import titleTextBlock from "./titleTextBlock";

export const fields = `
	_type,
  ${titleTextBlock("introTitleTextBlock")},
  ${titleTextBlock("aboutTitleTextBlock")},
  rectangeText,
  images {
    ${asset("tallImage")},
    ${asset("linesImage")},
    ${asset("diamondImage")},
  }
`;

export const fragment = (name = "getInvolvedHero") => `${name}{ ${fields} }`;

export default fragment;
