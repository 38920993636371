import asset from "./asset";

export const fields = `
	_type,
  title,
  description,
  circleDescription,
  qualificationsList1 {
    title,
    values[]
  },
  qualificationsList2 {
    title,
    values[]
  },
  ${asset("shapeImage")},
  noteTitle,
  noteText  
`;

export const fragment = (name = "bursariesEligibility") =>
    `${name}{ ${fields} }`;

export default fragment;
