import asset from "./asset";
import titleListBlock from "./titleListBlock";

export const fields = `
	_type,
  ${titleListBlock("schedule")},
  textBox {
    title,
    description,
    ${asset("image")}
  },
  parallaxImages {
    ${asset("image1")},
    ${asset("image2")},
    ${asset("image3")},
  }
`;

export const fragment = (name = "homeScheduleSection") =>
    `${name}{ ${fields} }`;

export default fragment;
