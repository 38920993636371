import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useMove } from "@use-gesture/react";
import gsap from "gsap";
import Cookies from "universal-cookie";
import { Flip } from "gsap/dist/Flip";

import styles, { offset } from "./IntroLoader.module.scss";

import SanityImage from "../SanityImage/SanityImage";

import useStore from "../../store";

import Logo from "../../assets/svgs/intro-loader-logo.svg";
import LogoFilled from "../../assets/svgs/intro-loader-logo-filled.svg";

import { lerp, simpleImagesPreload } from "../../utils";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

import { headerLogoClassName } from "../Header/Header";

gsap.registerPlugin(Flip);

const STROKE_WIDTH = 1;
const OFFSET = parseFloat(offset).toFixed(5);
const LERP = 0.08;
const COOKIE_NAME = "last-time-loader-shown";

const cookies = new Cookies();

function Rect({ className, rectRef }) {
    return (
        <svg className={className} viewBox="0 0 100 100">
            <path
                ref={rectRef}
                fill="none"
                stroke="currentColor"
                strokeWidth={STROKE_WIDTH}
                d="M 0 0 L 100 0 L 100 100 L 0 100 z"
            />
        </svg>
    );
}

function IntroLoader({ className, onComplete, preloadImages }) {
    const timeSinceLastLoader = cookies.get(COOKIE_NAME);
    const [done, setDone] = useState(false);
    const [canRunAnimation, setCanRunAnimation] = useState(false);
    const [doneBoxAnimation, setDoneBoxAnimation] = useState(false);
    const ranCookieLogic = useRef(false);
    const loaderPercent = useRef();
    const loaderBar = useRef();
    const containerRef = useRef();
    const requestRef = useRef();
    const boxContainer = useRef();
    const backImage = useRef();
    const isDoneAnimation = useRef(false);
    const haveFiredIntro = useRef(false);
    const tl = useRef();
    const posRef = useRef({ x: 0, y: 0, xNorm: 0 });
    const targetRef = useRef({ x: 0, y: 0, xNorm: 0 });
    const windowWidth = useStore((state) => state.windowWidth);
    const windowHeight = useStore((state) => state.windowHeight);
    const loaderData = useStore((state) => state.loaderData);
    const imagesToLoad = useStore((state) => state.imagesToLoad);
    const percentLoaded = useStore((state) => state.percentLoaded);
    const setCameFromLoader = useStore((state) => state.setCameFromLoader);
    const setPercentLoaded = useStore((state) => state.setPercentLoaded);
    const loadableImageRefs = useRef([]);
    const pushToImagesToLoad = useStore((state) => state.pushToImagesToLoad);
    const rectangleRefs = useRef({ front: null, middle: null, back: null });
    const sideLines = useRef({
        leftTop: null,
        leftBottom: null,
        rightTop: null,
        rightBottom: null,
    });
    const logoBoxBg = useRef();
    const { isMobile } = useBreakpoint();

    const handleMove = (e) => {
        const [x, y] = e.xy;

        const percentX = x / windowWidth;
        const percentY = y / windowHeight;

        targetRef.current.x = percentX;
        targetRef.current.y = percentY;
        targetRef.current.xNorm =
            percentX > 0.5 ? percentX * 2 - 1 : -1 * (1 - percentX * 2);
    };

    const animateOut = () => {
        const outTl = gsap.timeline();

        gsap.to([loaderPercent.current, loaderBar.current], {
            stagger: 0.4,
            autoAlpha: 0,
            duration: 0.6,
        });

        outTl.to(containerRef.current, {
            "--distance": `${isMobile ? OFFSET * 3 : OFFSET * 10}rem`,
            duration: 1.3,
            ease: "Power4.easeOut",
        });

        outTl.to(
            containerRef.current,
            {
                "--distance": 0,
                duration: 0.7,
                ease: "Power4.easeIn",
                onComplete: () => {
                    posRef.current.x = 0;
                    posRef.current.y = 0;
                    posRef.current.xNorm = 0;

                    setDone(true);

                    const headerLogo = document.querySelectorAll(
                        `.${headerLogoClassName}`
                    )[0];

                    Flip.fit(boxContainer.current, headerLogo, {
                        duration: 2,
                        ease: "Power4.easeInOut",
                        scale: true,
                        delay: 1,
                        onComplete: () => {
                            if (onComplete) onComplete();
                        },
                    });
                },
            },
            "-=0.6"
        );
    };

    const handleAnimateOutBoxAnimation = () => {
        isDoneAnimation.current = true;
        animateOut();
    };

    const animateIn = () => {
        const duration = 1.5;

        // Reset boxes
        Object.values(rectangleRefs.current).forEach((el, i) => {
            const length = el.getTotalLength();
            gsap.set(el, { strokeDasharray: length, strokeDashoffset: length });
        });

        tl.current = gsap.timeline();

        tl.current.to(rectangleRefs.current.front, {
            strokeDashoffset: 0,
            duration,
        });

        tl.current.to(
            [...Object.values(sideLines.current)],
            {
                x: 0,
                stagger: 0.2,
                duration,
            },
            `-=${duration * 0.75}`
        );

        tl.current.to(
            rectangleRefs.current.middle,
            {
                strokeDashoffset: 0,
                duration,
                onComplete: () => {
                    setDoneBoxAnimation(true);
                },
            },
            duration * 0.5
        );

        tl.current.to(
            rectangleRefs.current.back,
            {
                strokeDashoffset: 0,
                duration,
            },
            duration
        );

        tl.current.to(
            backImage.current,
            {
                autoAlpha: 1,
                duration: duration,
                ease: "power4.out",
            },
            duration
        );
    };

    const animateHover = () => {
        requestRef.current = requestAnimationFrame(animateHover);

        posRef.current = {
            x: lerp(posRef.current.x, targetRef.current.x, LERP),
            y: lerp(posRef.current.y, targetRef.current.y, LERP),
            xNorm: lerp(posRef.current.xNorm, targetRef.current.xNorm, LERP),
        };

        if (!isDoneAnimation.current) {
            const distanceMin = posRef.current.x + 0.5;
            if (containerRef.current) {
                containerRef.current.style.setProperty(
                    "--distance",
                    `${distanceMin * OFFSET * 2}rem`
                );
            }
        }

        const normalizedX = posRef.current.xNorm;

        if (boxContainer.current) {
            gsap.set(boxContainer.current, {
                rotate: normalizedX * 3,
                skewY: normalizedX > 0 ? normalizedX * 1 : 0,
                skewX: normalizedX > 0 ? 0 : -normalizedX * 0.1,
            });
        }
    };

    useEffect(() => {
        if (!canRunAnimation) return;

        if (!done) {
            animateHover();
        } else {
            if (requestRef.current) cancelAnimationFrame(requestRef.current);
        }

        if (!haveFiredIntro.current) {
            haveFiredIntro.current = true;
            setTimeout(() => {
                animateIn();
            }, 50);
        }
    }, [done, canRunAnimation]);

    useEffect(() => {
        if (!canRunAnimation) return;

        setTimeout(() => {
            setPercentLoaded(1);
        }, 5000);

        if (!imagesToLoad.length) return;

        simpleImagesPreload({
            urls: imagesToLoad,
            onProgress: (progress) => {
                gsap.killTweensOf(loaderBar.current);

                gsap.killTweensOf(loaderBar.current);
                gsap.to(loaderBar.current, {
                    scaleX: progress,
                    duration: 1.2,
                    ease: "Power3.easeOut",
                });

                setPercentLoaded(progress);
            },
        });
    }, [imagesToLoad, canRunAnimation]);

    useEffect(() => {
        if (percentLoaded !== 1 || !doneBoxAnimation) return;

        handleAnimateOutBoxAnimation();
    }, [doneBoxAnimation, percentLoaded]);

    useEffect(() => {
        if (ranCookieLogic.current) return;
        ranCookieLogic.current = true;

        const date = new Date().getTime();

        if (!timeSinceLastLoader) {
            setCanRunAnimation(true);
            setCameFromLoader(true);
            cookies.set(COOKIE_NAME, date, { path: "/" });
        } else {
            const msInDay = 1000 * 60 * 60 * 24;
            const timeDiff = date - parseInt(timeSinceLastLoader);

            if (timeDiff < msInDay) {
                if (onComplete) onComplete();
            } else {
                cookies.set(COOKIE_NAME, date, { path: "/" });
                setCanRunAnimation(true);
                setCameFromLoader(true);
            }
        }
    }, [timeSinceLastLoader]);

    // Find the srcSet src that the preload images have chose
    // and push those to the array of srcs to load
    useEffect(() => {
        if (canRunAnimation) {
            setTimeout(() => {
                loadableImageRefs.current.forEach((ref) => {
                    if (!ref) return;

                    const img = ref.querySelector("img");
                    if (img.currentSrc) {
                        pushToImagesToLoad(img.currentSrc);
                    }
                });
            }, 100);
        }
    }, [canRunAnimation]);

    const bind = useMove((state) => handleMove(state));

    return (
        <div
            {...bind()}
            className={classnames(styles.IntroLoader, className, {
                [styles.visiblyHidden]: !canRunAnimation,
            })}
            ref={containerRef}
        >
            {canRunAnimation && preloadImages?.length && (
                <div
                    style={{
                        opacity: 0,
                        position: "absolute",
                        pointerEvents: "none",
                        width: 100,
                    }}
                >
                    {preloadImages.map((preloadImageObject, i) => (
                        <SanityImage
                            image={preloadImageObject}
                            ref={(ref) => (loadableImageRefs.current[i] = ref)}
                            key={i}
                        />
                    ))}
                </div>
            )}
            <div className={styles.boxContainer} ref={boxContainer}>
                {!done && (
                    <>
                        <div className={styles.boxFront}>
                            <Rect
                                rectRef={(ref) =>
                                    (rectangleRefs.current.front = ref)
                                }
                            />
                        </div>
                        <div className={styles.boxLeftSide}>
                            <div
                                className={styles.topBoxLine}
                                ref={(ref) => (sideLines.current.leftTop = ref)}
                            />
                            <div
                                className={styles.bottomBoxLine}
                                ref={(ref) =>
                                    (sideLines.current.leftBottom = ref)
                                }
                            />
                        </div>
                        <div className={styles.boxRightSide}>
                            <div
                                className={styles.topBoxLine}
                                ref={(ref) =>
                                    (sideLines.current.rightTop = ref)
                                }
                            />
                            <div
                                className={styles.bottomBoxLine}
                                ref={(ref) =>
                                    (sideLines.current.rightBottom = ref)
                                }
                            />
                        </div>
                        <div className={styles.boxBackSide}>
                            <Rect
                                rectRef={(ref) =>
                                    (rectangleRefs.current.back = ref)
                                }
                            />
                            <SanityImage
                                className={styles.boxBackImage}
                                image={loaderData?.backImage}
                                ref={backImage}
                            />
                        </div>
                    </>
                )}
                <div
                    className={classnames(styles.boxMiddle, {
                        [styles.complete]: done,
                    })}
                >
                    <svg
                        viewBox="0 0 500 500"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className={styles.logoBox}
                    >
                        <path
                            ref={(ref) => (rectangleRefs.current.middle = ref)}
                            d="M5 12.4952C5 8.35571 8.35572 5 12.4952 5H487.505C491.644 5 495 8.35572 495 12.4952V487.505C495 491.644 491.644 495 487.505 495H12.4952C8.35571 495 5 491.644 5 487.505V12.4952Z"
                            fill="none"
                            strokeWidth={STROKE_WIDTH * 2}
                            className={styles.logoBoxPath}
                        />
                    </svg>
                    <div className={styles.logoBoxBg} ref={logoBoxBg} />
                    <div className={styles.logoContainer}>
                        <Logo className={styles.logo} />
                        <LogoFilled className={styles.logoFilled} />
                    </div>
                </div>
            </div>
            <div className={styles.loaderBar}>
                <div className={styles.loaderBarPercent} ref={loaderPercent}>
                    {`${percentLoaded * 100}`.split("").map((char, i) => (
                        <span key={i}>{char}</span>
                    ))}
                    %
                </div>
                <div className={styles.loaderBarBar} ref={loaderBar} />
            </div>
        </div>
    );
}

export default memo(IntroLoader);

<svg
    width="461"
    height="461"
    viewBox="0 0 461 461"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path d="M1 1H230.5H460V460H1V1Z" stroke="#1E1E1E" stroke-width="2" />
</svg>;
