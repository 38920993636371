import create from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import ScrollPosition from "../utils/singletons/scrollPosition";
import WindowSize from "../utils/singletons/windowSize";
import breakpoints from "../data/breakpoints";
import detect from "../utils/detect";

export const getBreakpoint = (width) => {
    if (width > 0) {
        for (const breakpoint of breakpoints) {
            if (width <= breakpoint.width) {
                return breakpoint;
            }
        }
    }

    return breakpoints[breakpoints.length - 1];
};

export const UIStore = create(
    subscribeWithSelector(
        persist(
            (set) => ({
                // Global page data
                headerData: {},
                setHeaderData: (headerData) => set({ headerData }),
                footerData: {},
                setFooterData: (footerData) => set({ footerData }),
                cookieModalData: {},
                setCookieModalData: (cookieModalData) =>
                    set({ cookieModalData }),
                loaderData: {},
                setLoaderData: (loaderData) => set({ loaderData }),
                sideText: "",
                setSideText: (sideText) => set({ sideText }),

                // Loader
                percentLoaded: 0,
                setPercentLoaded: (percentLoaded) => set({ percentLoaded }),
                imagesToLoad: [],
                setImagesToLoad: (imagesToLoad) => set({ imagesToLoad }),
                pushToImagesToLoad: (newImage) => {
                    return set({
                        imagesToLoad: [
                            ...UIStore.getState().imagesToLoad,
                            newImage,
                        ],
                    });
                },
                cameFromLoader: false,
                setCameFromLoader: (cameFromLoader) => set({ cameFromLoader }),

                // Fixed Video
                fixedVideoOpen: false,
                setFixedVideoOpen: (fixedVideoOpen) => set({ fixedVideoOpen }),

                // Header
                navIsOpen: false,
                setNavIsOpen: (navIsOpen) => set({ navIsOpen }),

                // Home
                homeIsFinishedAnimating: false,
                setHomeIsFinishedAnimating: (homeIsFinishedAnimating) =>
                    set({ homeIsFinishedAnimating }),

                // Page transitions
                pageIsTransitioning: false,
                setPageIsTransitioning: (pageIsTransitioning) =>
                    set({ pageIsTransitioning }),

                // General
                theme: "dark",
                setTheme: (theme) => set({ theme }),
                contentHeight: 0,
                setContentHeight: (contentHeight) => set({ contentHeight }),
                breakpoint: getBreakpoint(WindowSize.getSize().width),
                setBreakpoint: (breakpoint) => set({ breakpoint }),
                isStoreRehydrated: false,
                setIsStoreRehydrated: (isStoreRehydrated) =>
                    set({ isStoreRehydrated }),
                isScrollLocked: false,
                updateIsScrollLocked: (isScrollLocked) =>
                    set({ isScrollLocked }),
                scrollPositionX: ScrollPosition.getPosition().x,
                scrollPositionY: ScrollPosition.getPosition().y,
                scrollDirection: ScrollPosition.getPosition().direction,
                resetScrollDirection: () => set({ scrollDirection: undefined }),
                windowHeight: WindowSize.getSize().height,
                windowWidth: WindowSize.getSize().width,
                headerHeight: 0,
                updateHeaderHeight: (headerHeight) => set({ headerHeight }),
                deviceInfo: {
                    ...detect,
                },
            }),
            {
                name: "site-state",
                partialize: (state) => ({ theme: state.theme }),
                onRehydrateStorage: () => (state) => {
                    if (state && !state?.isLoaded) {
                        setTimeout(() => {
                            state.setIsStoreRehydrated(true); // Needs quick tick or else does not update...
                        }, 5);
                    }
                },
            }
        )
    )
);

WindowSize.subscribe((size) => {
    const currentState = UIStore.getState();
    const previousBreakpoint = currentState.breakpoint;
    const currentBreakpoint = getBreakpoint(size.width);

    if (previousBreakpoint !== currentBreakpoint) {
        UIStore.setState({ breakpoint: getBreakpoint(size.width) });
    }
    if (size.height !== currentState.windowHeight) {
        UIStore.setState({ windowHeight: size.height });
    }
    if (size.width !== currentState.windowWidth) {
        UIStore.setState({ windowWidth: size.width });
    }
});

ScrollPosition.subscribe((position) => {
    if (position.y <= 10) {
        return;
    }
    UIStore.setState({
        scrollPositionX: position.x,
        scrollPositionY: position.y,
        scrollDirection: position.direction,
    });
});

export default UIStore;
