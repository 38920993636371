import richText from "./richText";

export const fields = `
	_type,
  title,
  ${richText()}
`;

export const fragment = (name = "richTextSection") => `${name}{ ${fields} }`;

export default fragment;
