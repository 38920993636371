import asset from "./asset";

export const fields = `
	_type,
  title,
  images {
    ${asset("narrowImage")},
    ${asset("wideImage")}
  }
`;

export const fragment = (name = "scheduleSection") => `${name}{ ${fields} }`;

export default fragment;
