export const fields = `
  _type,
  theme,
  title,
  itinerary,
  date,
  year,
  location
`;

export const fragment = (name = "titleListBlock") => `${name}{ ${fields} }`;

export default fragment;
