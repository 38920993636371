import asset from "./asset";
import titleTextBlock from "./titleTextBlock";
import richText from "./richText";

export const fields = `
	_type,
  ${titleTextBlock("partnerTitleTextBlock")},
  boxContent {
    title,
    ${richText()}
  },
  images {
    ${asset("equationImage")},
    ${asset("lineImage")},
    ${asset("geometryImage")},
    ${asset("lowerEquationImage")},
  }
`;

export const fragment = (name = "getInvolvedHero") => `${name}{ ${fields} }`;

export default fragment;
