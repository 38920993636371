import axios from "axios";

const base = `https://api.pheedloop.com/api/v3/organization/${process.env.PHEEDLOOP_ORG}/events/${process.env.PHEEDLOOP_EVENT}`;

const headers = {
    "X-API-KEY": process.env.PHEEDLOOP_X_API_KEY,
    "X-API-SECRET": process.env.PHEEDLOOP_X_SECRET_KEY,
    "Content-Type": "application/json",
};

export const getSpeakers = () => {
    return axios.get(`${base}/speakers/`, { headers });
};

export const getSessions = () => {
    return axios.get(`${base}/sessions/`, { headers });
};
