import React, { memo } from "react";
import classnames from "classnames";

import styles from "./Highlight.module.scss";

import highlight from "../../assets/images/highlight.webp";
import highlightLong from "../../assets/images/highlight-long.webp";

function Highlight({ className, isLong = false }) {
    return (
        <img
            src={isLong ? highlightLong.src : highlight.src}
            alt=""
            className={classnames(className, styles.highlight)}
        />
    );
}

export default memo(Highlight);
