export const fields = `
	_type,
  text,
  acceptButtonText,
  rejectButtonText
`;

export const fragment = (name = "cookieModal") => `${name}{ ${fields} }`;

export default fragment;
