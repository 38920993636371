import Image from "next/image";
import React, { memo, useRef } from "react";
import classnames from "classnames";
import { useNextSanityImage } from "next-sanity-image";

import { client as sanityClient } from "../../data-interface/sanity.js";

import styles from "./SanityImage.module.scss";

const SanityImage = React.forwardRef(
    ({ className, image, id, onLoad }, ref) => {
        if (!image) return null;

        const imageProps = useNextSanityImage(sanityClient, image);

        const alt = image?.alt;

        return (
            <div
                ref={ref}
                className={classnames(styles.SanityImage, className)}
            >
                <Image
                    onLoadingComplete={(e) => {
                        if (onLoad) onLoad(e);
                    }}
                    {...imageProps}
                    sizes={`75vw`}
                    id={id}
                    alt={alt ? alt : ""}
                    placeholder="blur"
                    blurDataURL={image.asset.metadata.lqip}
                    className={styles.image}
                />
            </div>
        );
    }
);

export default memo(SanityImage);
