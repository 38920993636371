import * as fragments from "./index.js";

const validTypes = [
    "homeHero",
    "homeScheduleSection",
    "ctaSection",
    "fixedVideo",
    "ticketPlans",
    "faqAccordion",
    "getInvolvedHero",
    "getInvolvedPartnerHostSection",
    "contactUsSection",
    "aboutHero",
    "aboutBoxSection",
    "aboutEdmontonSection",
    "fixedNavigationSection",
    "logosSection",
    "gallery",
    "bursariesHero",
    "bursariesAbout",
    "bursariesEligibility",
    "bursariesDiversity",
    "scheduleSection",
    "speakersSection",
    "richTextSection",
];

const typesMapped = validTypes
    .map((typeName) => {
        if (fragments[typeName]) {
            return `_type == "${typeName}" => { ${fragments[typeName].fields} }\n`;
        }
    })
    .join(",");

export const fragment = (name = "content") => `${name}[]{ 
  ${typesMapped}
}`;

export default fragment;
