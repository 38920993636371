import asset from "./asset";
import { fields as linkFields } from "./link";

export const fields = `
	_type,
  ${asset("logo")},
  links[] {
    ${linkFields}
  }
`;

export const fragment = (name = "footer") => `${name}{ ${fields} }`;

export default fragment;
