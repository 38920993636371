import asset from "./asset";
import richText from "./richText";

export const fields = `
	_type,
  ${richText("boxContent")},
  ${asset("equationImage")},
  slidingScaleInfo {
    title,
    values[] {
      category,
      amount
    }
  }  
`;

export const fragment = (name = "bursariesAbout") => `${name}{ ${fields} }`;

export default fragment;
