import { fields as assetFields } from "./asset";

export const fields = `
	_type,
  title,
  logos[] {
    ${assetFields}
  }
`;

export const fragment = (name = "logosSection") => `${name}{ ${fields} }`;

export default fragment;
