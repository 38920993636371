import asset from "./asset";
import titleTextBlock from "./titleTextBlock";

export const fields = `
	_type,
  ${titleTextBlock()},
  images {
    ${asset("imageTop")},
    ${asset("imageMiddle")},
    ${asset("imageBottom")},
  }
`;

export const fragment = (name = "getInvolvedHero") => `${name}{ ${fields} }`;

export default fragment;
