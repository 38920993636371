export const fields = `
	_id,
	_key,
	alt,
	preload,
	_type,
	asset->,
	crop,
	hotspot,
`;

export const fragment = (name, extraFields = null) => `${name}{
	${extraFields !== null ? extraFields + "," : ""}
	${fields}
}`;

export default fragment;
