import React, { useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import classnames from "classnames";
import gsap from "gsap";
import { useRouter } from "next/router";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import { useTransitionFix } from "../../utils/hooks/use-transition-fix";
import styles from "./Transition.module.scss";
import useStore from "../../store";

export const TRANSITION_TIME = 0.3;
export const TRANSITION_TIME_MS = TRANSITION_TIME * 1000;

const variants = {
    hidden: { opacity: 0 },
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0 },
};

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

const Transition = ({ children, location, scrollContainerRef }) => {
    const router = useRouter();
    const { asPath } = router;
    const contentRef = useRef();
    useTransitionFix();
    const setPageIsTransitioning = useStore(
        (state) => state.setPageIsTransitioning
    );
    const homeIsFinishedAnimating = useStore(
        (state) => state.homeIsFinishedAnimating
    );

    // Showing homepage
    useEffect(() => {
        if (asPath === "/" && homeIsFinishedAnimating) {
            gsap.set(scrollContainerRef.current, {
                overflow: "initial",
            });
        }
    }, [asPath, homeIsFinishedAnimating]);

    return (
        <AnimatePresence mode="wait" initial={false}>
            <motion.main
                ref={contentRef}
                className={classnames(styles.transitionContainer)}
                initial="hidden"
                animate="enter"
                exit="exit"
                variants={variants}
                onAnimationStart={(state) => {
                    if (state === "exit") {
                        gsap.set(contentRef.current, {
                            y: window.pageYOffset,
                        });
                        setPageIsTransitioning(true);
                    }
                    if (state === "enter") {
                        gsap.set(scrollContainerRef.current, {
                            overflow: "hidden",
                        });

                        window.scrollTo(0, 0);
                        const int = setInterval(() => {
                            window.scrollTo(0, 0);
                        }, 20);

                        setTimeout(() => {
                            clearInterval(int);
                        }, TRANSITION_TIME_MS);
                    }
                }}
                onAnimationComplete={(state) => {
                    if (state === "enter") {
                        setPageIsTransitioning(false);

                        if (asPath !== "/") {
                            gsap.set(scrollContainerRef.current, {
                                overflow: "initial",
                                delay: TRANSITION_TIME,
                            });

                            ScrollTrigger.refresh();
                        }
                    }
                }}
                transition={{ ease: "circOut", duration: TRANSITION_TIME }}
                key={location}
                id="main"
            >
                {children}
            </motion.main>
        </AnimatePresence>
    );
};
export default Transition;
