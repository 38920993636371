import { useEffect, useState } from "react";
import useStore from "../../store";

import {
    mobile,
    tablet,
    laptop,
    desktop,
    xl,
} from "../../styles/export-vars.module.scss";

const BREAKPOINTS = {
    mobile,
    tablet,
    laptop,
    desktop,
    xl,
};

const getBreakpointByWidth = (windowWidth) => {
    let breakpoint = null;

    Object.values(BREAKPOINTS).forEach((bpValue, i) => {
        const bp = parseInt(bpValue);
        const beginningSize =
            i === 0 ? 0 : parseInt(Object.values(BREAKPOINTS)[i - 1]);
        const endingSize =
            i === Object.values(BREAKPOINTS).length - 1 ? 10000 : bp;

        if (windowWidth > beginningSize && windowWidth <= endingSize) {
            breakpoint = {
                name: Object.keys(BREAKPOINTS)[i],
                width: windowWidth,
            };
        }
    });

    return breakpoint;
};

function useBreakpoint() {
    const windowWidth = useStore((state) => state.windowWidth);
    const [breakpoint, setBreakpoint] = useState(
        getBreakpointByWidth(windowWidth)
    );

    useEffect(() => {
        const bp = getBreakpointByWidth(windowWidth);
        setBreakpoint(bp);
    }, [windowWidth]);

    return {
        breakpoint,
        isMobile: breakpoint?.name === "mobile",
    };
}

export default useBreakpoint;
