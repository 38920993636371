import React, { memo, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import checkProps from "@jam3/react-check-extra-props";
import Cookies from "universal-cookie";
import gsap from "gsap";

import useStore from "../../store";

import styles from "./CookieModal.module.scss";

import Highlight from "../Highlight/Highlight";

export const ALLOW_COOKIES_NAME = "allow-cookies";

const cookies = new Cookies();

const COOKIE_OPTIONS = {
    maxAge: 86400,
    path: "/",
};

function CookieModal({ className }) {
    const [clickedButton, setClickedButton] = useState(false);
    const hasCookie = cookies.get(ALLOW_COOKIES_NAME);
    const cookieModalData = useStore((state) => state.cookieModalData);
    const container = useRef();

    if (!cookieModalData) return null;

    useEffect(() => {
        if (!container.current) return;

        gsap.fromTo(
            container.current,
            {
                y: "100%",
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                delay: 0.4,
                duration: 0.8,
                ease: "Power3.easeOut",
            }
        );
    }, []);

    const handleAcceptClick = () => {
        cookies.set(ALLOW_COOKIES_NAME, "true", COOKIE_OPTIONS);
        window.location.reload();
        setClickedButton(true);
    };

    const handleRejectClick = () => {
        cookies.set(ALLOW_COOKIES_NAME, "false", COOKIE_OPTIONS);
        setClickedButton(true);
    };

    return (
        <>
            {!clickedButton && !hasCookie && (
                <div
                    ref={container}
                    className={classnames(styles.CookieModal, className)}
                >
                    <p className={styles.text}>{cookieModalData.text}</p>
                    <div className={styles.buttons}>
                        <button
                            className={styles.button}
                            onClick={() => {
                                handleAcceptClick();
                            }}
                            data-highlight-container
                        >
                            {cookieModalData.acceptButtonText}
                            <Highlight className={styles.highlight} />
                        </button>
                        <button
                            className={styles.button}
                            onClick={() => {
                                handleRejectClick();
                            }}
                            data-highlight-container
                        >
                            {cookieModalData.rejectButtonText}
                            <Highlight className={styles.highlight} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

CookieModal.propTypes = checkProps({
    className: PropTypes.string,
});

CookieModal.defaultProps = {};

export default memo(CookieModal);
