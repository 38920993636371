import file from "./file";

export const fields = `
  _type,
  ${file("videoThumbnail")},
  vimeoId
`;

export const fragment = (name = "fixedVideo") => `${name}{ ${fields} }`;

export default fragment;
