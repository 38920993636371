import { useEffect } from "react";
import useStore from "../../store";
import {
    gridColumns,
    gridColumnsMobile,
} from "../../styles/export-vars.module.scss";
import useBreakpoint from "./use-breakpoint";

const BASE_SIZE = {
    DESKTOP: 1280,
    MOBILE: 375,
};

const BASE_FONT_SIZE = 10;

export default function useRemSizing() {
    const windowWidth = useStore((state) => state.windowWidth);
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        const sizeToCompare = isMobile ? BASE_SIZE.MOBILE : BASE_SIZE.DESKTOP;

        const size = windowWidth / sizeToCompare;

        const remValue = size * BASE_FONT_SIZE;
        document.documentElement.style.fontSize = `${remValue}px`;
        document.documentElement.style.setProperty(
            "--rem",
            `${remValue / BASE_FONT_SIZE}rem`
        );

        const cols = isMobile
            ? parseInt(gridColumnsMobile)
            : parseInt(gridColumns);

        const colSize = windowWidth / cols;
        document.documentElement.style.setProperty(
            "--col-size",
            `${colSize}px`
        );
        document.documentElement.style.setProperty(
            "--col-size-half",
            `${colSize / 2}px`
        );
    }, [windowWidth, isMobile]);

    return {};
}
