import link from "./link";

export const fields = `
	_type,
  theme,
  desktopCtaText,
  mobileCtaText,
  desktopFixedNavigationText,
  ${link("cta")}
`;

export const fragment = (name = "aboutEdmontonSection") =>
    `${name}{ ${fields} }`;

export default fragment;
